import { Link } from "react-router-dom"
import { MoneyTextFormat } from "../money"
import { useProps } from "@mui/x-data-grid/internals"
import DeleteDialog from "../deletedialog"
import React from "react"
import { DataGrid } from "@mui/x-data-grid"
import { DatePicker } from '@mui/x-date-pickers';
import { Card, CardContent, Container, Paper, Step, StepLabel, Toolbar, Typography, Grid, TextField, Button, TableContainer, TableBody, TableRow, Table, TableHead, TableCell, CardMedia, CardActions, Select, MenuItem, InputLabel, Input, Slider, CardHeader, InputAdornment, Breadcrumbs, Accordion, AccordionSummary, AccordionDetails, styled, containerClasses, Modal, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, IconButton, Alert, Checkbox, FormControl, FormControlLabel } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import dayjs from "dayjs"

function Period(props) {

    const [showDelete, setShowDelete] = React.useState()

    var [helpText, setHelpText] = React.useState(false)

    function orderPeriods(periods) {
        periods.sort((a, b) => {
            if (a.start == null || b.start == null) {
                return 1
            }

            return dayjs(a.start,'YYYY-MM-DD').isAfter(dayjs(b.start,'YYYY-MM-DD')) ? 1 : -1
        })
    }

    var overlappingPeriods = (() => {
        var overlap = false
        props.periods.forEach((period) => {
            var find = props.periods.find((f) => {
                return period.start && period.start != f.start && dayjs(period.start, 'YYYY-MM-DD') >= dayjs(f.start,'YYYY-MM-DD') && (!f.end || dayjs(period.start,'YYYY-MM-DD') <= dayjs(f.end, 'YYYY-MM-DD'))
            })

            if (find) {
                overlap = true
            }
        })

        return overlap
    })()

    return <>
        {showDelete &&
            <DeleteDialog delete={showDelete.delete} name={showDelete.name} handleClose={() => setShowDelete(null)} />
        }
        {helpText &&
            <Dialog
                open={true}
                onClose={() => {
                    setHelpText(null)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Help
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {helpText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={() => setHelpText(null)}>Ok</Button>
                </DialogActions>
            </Dialog>
        }
        <Grid container spacing={2} item xs={12}>
            <Grid container item xs={12}>
                <Grid item xs={6}>
                    <Typography variant='h5'>Periods</Typography>
                </Grid>
                <Grid item xs={6} container justifyContent='flex-end'>
                    <Button onClick={() => setHelpText("Periods.... by not setting an End Date, the period will recur year on year.")}>LEARN MORE</Button>
                </Grid>
            </Grid>
            { overlappingPeriods &&
                <Grid item xs={12}>
                    <Alert severity="error">Overlapping periods are not allowed</Alert>
                </Grid>
            }
            <Grid item xs={12}>
                <Paper>
                    <DataGrid disableColumnResize autoHeight disableColumnMenu disableRowSelectionOnClick columns={
                        [
                            {
                                field: 'id'
                            },
                            {
                                field: 'start',
                                flex: 0.25,
                                headerName: 'Start',
                                renderCell: (a) => {
                                    return a.row.start ? a.row.start.format('MM-YYYY') : '-'
                                }
                            },
                            {
                                field: 'end',
                                flex: 0.25,
                                headerName: 'End',
                                renderCell: (a) => {
                                    return a.row.end ? a.row.end.format('MM-YYYY') : '-'
                                }
                            },
                            {
                                field: 'amount',
                                flex: 0.3,
                                headerName: 'Amount',
                                renderCell: (a) => {
                                        return a.row.amount?.percent ? `${(a.row.amount.percent * 100).toFixed(2)}%` : a.row.amount ? <MoneyTextFormat value={a.row.amount} /> : '-'
                                }
                            },
                            {
                                field: 'edit',
                                align: 'right',
                                flex: 0.1,
                                renderCell: (a) => {
                                    return <IconButton component={Link} state={{ scroll: true }} onClick={() => props.editLink(a.row.start)}><EditIcon /></IconButton>
                                },
                                headerName: ''
                            },
                            {
                                field: 'delete',
                                align: 'right',
                                flex: 0.1,
                                renderCell: (a) => {
                                    return <IconButton onClick={() => {
                                        setShowDelete({
                                            delete: () => {
                                                var v = a.row.start ? a.row.start.format('YYYY-MM-DD') : null
                                                var f = props.periods.findIndex((f) => f.start == v)
                                                props.periods.splice(f, 1)

                                                props.update()

                                                setShowDelete(null)
                                            },
                                            name: `the period starting ${a.row.start ? a.row.start.format('YYYY-MM-DD') : '-'}`
                                        })
                                    }}><DeleteIcon /></IconButton>
                                },
                                headerName: ''
                            }
                        ]
                    } initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false
                            }
                        },
                        pagination: {
                            paginationModel: {
                                pageSize: 5
                            }
                        }
                    }} rows={props.periods.map((c) => {
                        return {
                            id: c.start ? `${c.start}${c.amount}` : 1,
                            start: c.start ? dayjs(c.start, 'YYYY-MM-DD') : null,
                            end: c.end ? dayjs(c.end, 'YYYY-MM-DD') : null,
                            amount: props.getAmount(c)
                        }

                    })}
                        rowSelectionModel={[props.selectedPeriod.start ? `${props.selectedPeriod.start}${props.selectedPeriod.amount}` : 1]}
                    />
                </Paper>
            </Grid>
            <Grid container item justifyContent='flex-end' xs={12}>
                <Button onClick={() => {

                    props.addPeriod(props.periods)

                    props.update()
                }
                } variant='contained'>Add</Button>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Typography variant='h5'>Payment Period</Typography>
        </Grid>
        <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
                <Paper style={{ padding: '20px' }}>
                    <Grid container item spacing={2} xs={12}>
                        <Grid container item spacing={2} xs={12}>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="standard-adornment-amount">Start</InputLabel>
                                <DatePicker
                                    value={props.selectedPeriod.start ? dayjs(props.selectedPeriod.start, 'YYYY-MM-DD') : null}
                                    views={props.monthly ? ['month', 'year'] : ['year']}
                                    format={props.monthly ? "MM/YYYY" : "YYYY"}
                                    slotProps={{ textField: { fullWidth: true } }}
                                    onChange={(e) => {
                                        orderPeriods(props.periods)
                                        props.selectedPeriod.start = e ? (props.monthly ? e.date(1) : e.date(1).month(0)).format('YYYY-MM-DD') : null
                                        props.update()
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="standard-adornment-amount">End</InputLabel>
                                <DatePicker
                                    value={props.selectedPeriod.end ? dayjs(props.selectedPeriod.end, 'YYYY-MM-DD') : null}
                                    views={props.monthly ? ['month', 'year'] : ['year']}
                                    format={props.monthly ? "MM/YYYY" : "YYYY"}
                                    disabled={!props.selectedPeriod.start}
                                    minDate={dayjs(props.selectedPeriod.start, 'YYYY-MM-DD')}
                                    slotProps={{ textField: { fullWidth: true }, actionBar: { actions: ['clear','accept','cancel'] } }}
                                    onChange={(e) => {
                                        orderPeriods(props.periods)
                                        props.selectedPeriod.end = e ? (props.monthly ? e.date(1) : e.date(31).month(11)).format('YYYY-MM-DD') : null
                                        props.update()
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {props.children}
                    </Grid>


                </Paper>

            </Grid>

        </Grid>

    </>
}

export default Period