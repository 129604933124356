import '../App.css';
import Stepper from '@mui/material/Stepper'
import { AppBar, Container, Paper, Step, StepLabel, Toolbar, Typography, Grid, TextField, Button, Input, InputAdornment, InputLabel, TableContainer, TableBody, TableCell, Table, TableHead, TableRow, Alert, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemIcon, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Select, MenuItem, Box, Tabs, Tab, duration, Card, CardMedia, CardContent, useTheme, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { calculateSavings, finances, getAnnualIncome, getExpense, getNetAnnualIncome, getSavingsAnnualTotal, saveJson } from '../store/store';
import { MoneyFormat, MoneyTextFormat, PercentageFormat } from '../money';
import ImageIcon from '@mui/icons-material/Image';
import HouseIcon from '@mui/icons-material/House';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import BuildIcon from '@mui/icons-material/Build';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import MonthlyAnnualAmmount from '../MonthlyAnnualAmount';
import { ExpenseMap } from '../expenses/Expense';
import { useFinances, useFinancesDispatch } from '../store/FinancesContext';
import FormHelperText from '@mui/material/FormHelperText';

import SavingsIcon from '@mui/icons-material/Savings';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { current } from 'immer';
import { v4 as uuidv4 } from 'uuid';


function Collect({ setDone }) {

  const [activeStep, setActiveStep] = React.useState(0);
  const [housingType, setHousingType] = React.useState('rent')
  const [hasPartner, setHasPartner] = React.useState(false)

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  var navigate = useNavigate()

  var currentYear = dayjs().year()

  var finances = useFinances()
  var financesDispatch = useFinancesDispatch()

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //window.scrollTo(0,0)
  };

  var net = finances.income.length > 0 ? getAnnualIncome(finances, currentYear) : null
  var remaining = net ? net.net - 16000 : 0


  if (finances.income.length == 0) {
    var firstincome = {
      name: "Job",
      from: 'employer',
      id: uuidv4(),
      who: 'you',
      taxable: true,
      inflate: true,
      pension: {
        type: 'definedcontribution',
        lumpSum: 0.25,
        takePensionAge: 65
      },
      periods: [
        {
          start: `${dayjs().year()}-01-01`,
          end: null,
          gross: 28000,
          employeeContribution: 0.05,
          employerContribution: 0.05
        }
      ]
    }
    financesDispatch({
      type: 'reload',
      item: {
        income: [firstincome],
        expenses: [{
          type: 'rent',
          id: uuidv4(),
          inflate: true,
          periods: [{
            start: `${dayjs().year()}-01-01`,
            end: null,
            pay: 'net',
            amount: 5000
          }]
        }],
        savings: [
          {
            name: 'Savings',
            id: uuidv4(),
            type: 'general',
            periods: [
              {
                start: `${currentYear}-01-01`,
                end: null,
                amount: 0,
                percent: 0.08,
                interest: 0.05
              }
            ]
    
          },
          {
            name: 'ISA',
            id: uuidv4(),
            type: 'isa',
            periods: [
              {
                start: `${currentYear}-01-01`,
                end: null,
                amount: 0,
                percent: 0.15,
                interest: 0.07
              }
            ]
    
          },
          {
            name: 'Emergency Fund',
            id: uuidv4(),
            type: 'emergencyfund',
            periods: [
              {
                start: `${currentYear}-01-01`,
                end: null,
                amount: 0,
                monthly: 0,
                interest: 0.05
              }
            ]
          }
        ],
        credit: [],
        loans: [],
      }
    })

  }

  // 1100
  // 20% - fun
  // 35% - savings
  // 45% - mortgage


  var updateExp = (value, id, name) => {
    var exp = {
      id: id,
      name: name,
      inflate: true,
      type: id,
      periods: [
        {
          start: `${currentYear}-01-01`,
          pay: id === 'holiday' ? 'savings' : 'net',
          end: null,
          amount: parseFloat(value)
        }
      ]

    }

    if (finances.expenses.find((f) => f.type == id)) {
      financesDispatch({
        type: 'update',
        financeType: 'expenses',
        item: exp
      })
    } else {
      financesDispatch({
        type: 'add',
        financeType: 'expenses',
        item: exp
      })
    }

  }

  var isaTot = finances.savings[0] ? calculateSavings(finances, finances.savings[0], false) : 0
  var savingsTot = finances.savings[1] ? calculateSavings(finances, finances.savings[1], false) : 0

  return (

    <>

      <Grid container item spacing={2}>
        <Grid item xs={12}>

          {activeStep == 0 &&


            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h5'>How much do you earn?</Typography>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: '20px' }}>
                  <Grid item xs={12}>
                    <TextField InputProps={{
                      inputComponent: MoneyFormat,
                      startAdornment: <InputAdornment position="start">£</InputAdornment>,
                      endAdornment: <InputAdornment position='end'>per year</InputAdornment>,

                    }} fullWidth id="outlined-basic" key={`amoun`} onChange={(e) => {
                      financesDispatch({
                        type: 'update',
                        financeType: 'income',
                        item: {
                          ...finances.income[0],
                          periods: finances.income[0].periods.map((m) => {
                            m.gross = parseFloat(e.target.value)
                            return m
                          })

                        }
                      })

                    }} value={finances.income[0]?.periods[0].gross} variant="outlined" />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Did you know, you could...</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{ padding: '20px', height: '55px' }}>
                  <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                    <Grid item md={3} xs={3}>
                      <Avatar sx={{ bgcolor: '#1976d2', width: '56px', height: '56px' }}>
                        <HouseIcon fontSize='large' />
                      </Avatar>
                    </Grid>
                    <Grid item md={9} xs={9}>
                      Put <b><MoneyTextFormat value={((finances.income[0]?.periods[0].gross) * 4)} /></b> towards a house.
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper style={{ padding: '20px', height: '55px' }}>
                  <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                    <Grid item md={3} xs={3}>
                      <Avatar sx={{ bgcolor: '#1976d2', width: '56px', height: '56px' }}>
                        <AirplanemodeActiveIcon fontSize='large' />
                      </Avatar>
                    </Grid>
                    <Grid item md={9} xs={9}>
                      Spend up to <b><MoneyTextFormat value={Math.round(savingsTot[0]?.total)} /></b> each year on holidays, home improvements, etc.
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{ padding: '20px', height: '55px' }}>
                  <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                    <Grid item md={3} xs={3}>
                      <Avatar sx={{ bgcolor: '#1976d2', width: '56px', height: '56px' }}>
                        <EmojiPeopleIcon fontSize='large' />
                      </Avatar>
                    </Grid>
                    <Grid item md={9} xs={9}>
                      Still have <b><MoneyTextFormat value={(Math.round((remaining * 0.2) / 12))} /></b> left at the end of the month to spend on whatever you want
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} >
                <Button onClick={() => {
                  handleNext()
                  financesDispatch({
                    type: 'add',
                    financeType: 'income',
                    item: {
                      name: "Partner",
                      from: 'employer',
                      who: 'partner',
                      taxable: true,
                      inflate: true,
                      pension: {
                        type: 'definedcontribution',
                        lumpSum: 0.25,
                        takePensionAge: 65
                      },
                      periods: [
                        {
                          start: `${currentYear}-01-01`,
                          end: null,
                          gross: 28000,
                          employeeContribution: 0.05,
                          employerContribution: 0.05
                        }
                      ]
                    }
                  })
                }} variant="contained" fullWidth>Get Started</Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">How MyFinance can help</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={"planning.jpg"}
                  />
                  <CardContent style={{ minHeight: '150px' }}>
                    <Typography gutterBottom variant="h5" component="div">
                      Planning
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      MyFinance helps you plan for the future. Whether that be saving for a house, a wedding, retirement or raising a family. MyFinance helps you make decisions 5, 10, 20+ years out by forecasting <a target='_blank' href="https://www.moneyhelper.org.uk/en/savings/how-to-save/inflation-what-the-saver-needs-to-know">inflation</a>, <a target='_blank' href="https://www.moneyhelper.org.uk/en/savings/how-to-save/interest-rates-explained">interest</a>, etc. See the power of your money in real terms 10+ years down the line with a financial plan.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={"budget.jpg"}
                  />
                  <CardContent style={{ minHeight: '150px' }}>
                    <Typography gutterBottom variant="h5" component="div">
                      Budgeting
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      How much money you have left at the end of each month is a strong determinant in your quality of life; budgeting allows you to balance your 'fun money' with your longer term financial goals. With this baseline set, you can better impact new financial situations (purchasing a phone, starting a subscription, etc.).
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Want to learn more?</Typography>
              </Grid>
              <Grid item xs={12} >
                <blockquote class="wp-embedded-content" data-secret="Z3r2fE65Mk"><a href="https://simonodonoghue.blog/2024/10/21/family-finances/">Family Finances</a></blockquote><iframe sandbox="allow-scripts" security="restricted" src="https://simonodonoghue.blog/2024/10/21/family-finances/embed/#?secret=Z3r2fE65Mk" width="100%" height="400" title="&#8220;Family Finances&#8221; &#8212; Simon O&#039;Donoghue" data-secret="Z3r2fE65Mk" frameborder="0" marginwidth="0" marginheight="0" scrolling="no" class="wp-embedded-content"></iframe>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Features</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={"https://maps.org.uk/content/dam/maps-corporate/en/brand-logos/MaPS_Land_Pos_RGB.svg"}
                  />
                  <CardContent style={{ minHeight: '150px' }}>
                    <Typography gutterBottom variant="h5" component="div">
                      Personalised Guidance
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Cut through the guidance that isn't relevant to you and receive guidance from the <a target='_blank' href="https://www.moneyhelper.org.uk/en">Money and Pensions Service</a> tailored to your specific financial circumstances. As you financial position changes, so does your guidance.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={"options.jpg"}
                  />
                  <CardContent style={{ minHeight: '150px' }}>
                    <Typography gutterBottom variant="h5" component="div">
                      Scenario Planner
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Whenever you come to making a significant financial decision, use the MyFinance Scenario Planner to see the short and long term impacts of financial decisions.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={"forecasting.jpg"}
                  />
                  <CardContent style={{ minHeight: '150px' }}>
                    <Typography gutterBottom variant="h5" component="div">
                      Forecasting
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Over the 20+ years of your financial plan, account for <a target='_blank' href="https://www.moneyhelper.org.uk/en/savings/how-to-save/inflation-what-the-saver-needs-to-know">inflation</a> in expenses, tax bands, etc. Always see your money in real terms.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardMedia
                    component="img"
                    alt="green iguana"
                    height="140"
                    image={"https://www.moneyhelper.org.uk/content/dam/maps/en/home-page/older-woman-smiling.jpg.pic.624.312.low.jpg"}
                  />
                  <CardContent style={{ minHeight: '150px' }}>
                    <Typography gutterBottom variant="h5" component="div">
                      Retirement Planner
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      MyFinance allows you to model <a target='_blank' href="https://www.moneyhelper.org.uk/en/pensions-and-retirement/pensions-basics/defined-contribution-pension-schemes">Defined Contribution</a> and <a target='_blank' href="https://www.moneyhelper.org.uk/en/pensions-and-retirement/pensions-basics/defined-benefit-or-final-salary-pensions-schemes-explained">Defined Benefit</a> pensions. Additionally, you can model investments such as ISAs and take income from them in retirement.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">We care about Financial Literacy</Typography>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} md={4}>
                  <Paper style={{ padding: '20px', height: '60px' }}>
                    <Typography>According to the <b><a target='_blank' href="https://www.ftadviser.com/your-industry/2024/07/03/nearly-24mn-uk-adults-have-poor-financial-literacy/">Financial Times</a></b>, 50% of adults struggle managing their finances</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{ padding: '20px', height: '60px' }}>
                    According to the <b><a target='_blank' href="https://www.fca.org.uk/news/press-releases/improving-picture-personal-finances-many-still-struggling#:~:text=New%20research%20from%20the%20Financial,10.9m%20in%20January%202023.">Financial Conduct Authority</a></b>, 7 million people are struggling to pay their bills
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{ padding: '20px', height: '60px' }}>
                    According to <b><a target='_blank' href="https://www.stepchange.org/Portals/0/23/policy/syb2023/StepChange_Statistics_Yearbook_2023_StepChange.pdf">Step Change</a></b>, the average unsecured debt of someone seeking help is <b>£14,654</b>.
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper style={{ padding: '20px' }}>
                    <Grid container direction='row' justifyContent='flex-end' alignItems='center'>


                      {matches && <Grid item xs={2}>
                        <Avatar sx={{ width: matches ? '128px' : '64px', height: matches ? '128px' : '64px' }} alt="Remy Sharp" src="simon.png" />
                      </Grid>}
                      <Grid item xs={12} md={10}>
                        <Typography paragraph>I created MyFinance because I care about making peoples lives easier. That doesn't mean everyone can be rich if they manage their finances, but it means people have a better chance of living within their means and reduce financial stress.</Typography>
                        <Grid container>
                          {!matches && <Grid item xs={3}>
                            <Avatar sx={{ width: matches ? '128px' : '64px', height: matches ? '128px' : '64px' }} alt="Remy Sharp" src="simon.png" />
                          </Grid>}
                          <Grid item>
                            <Typography><i><a target='_blank' href="https://www.linkedin.com/in/simon-o-donoghue-66015b30?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B66TfFuD8T2mJFbu%2BbIJFtw%3D%3D">Simon O'Donoghue (Founder)</a></i></Typography>
                          </Grid>
                        </Grid>
                      </Grid>



                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          }
          {activeStep == 1 &&
            <Grid container spacing={2}>
              <Grid item container xs={12}>
                <Paper style={{ width: '100%', padding: '20px' }}>
                  <Typography paragraph>What is your date of birth?</Typography>
                  <DatePicker key={`you-dob`}
                    slotProps={{ textField: { fullWidth: true } }}
                    value={finances.personal?.dob?.you ? dayjs(finances.personal?.dob?.you, 'YYYY-MM-DD') : null}
                    onChange={(e) => {
                      financesDispatch({
                        type: 'update',
                        financeType: 'personal',
                        item: {
                          ...finances.personal,
                          dob: {
                            ...finances.personal?.dob,
                            you: e ? e.format('YYYY-MM-DD') : null
                          }
                        }
                      })
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item container xs={12}>
                <Paper style={{ width: '100%', padding: '20px' }}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Typography paragraph>Do you have a partner who's income you wish to add to your financial plan?</Typography>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={hasPartner ? 0 : 1} variant='fullWidth' aria-label="basic tabs example">
                          <Tab label="Yes" onClick={() => setHasPartner(true)} />
                          <Tab label="No" onClick={() => setHasPartner(false)} />
                        </Tabs>
                      </Box>
                    </Grid>
                    {hasPartner &&
                      <>
                        <Grid item xs={12}>
                          <Typography paragraph>What is their income?</Typography>
                          <TextField InputProps={{
                            inputComponent: MoneyFormat,
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                            endAdornment: <InputAdornment position='end'>per year</InputAdornment>,

                          }} fullWidth id="outlined-basic" value={finances.income[1].periods[0].gross} onChange={(e) => {
                            financesDispatch({
                              type: 'update',
                              financeType: 'income',
                              item: {
                                ...finances.income[1],
                                periods: finances.income[1].periods.map((m) => {
                                  m.gross = parseInt(e.target.value)
                                  return m
                                })
                              }
                            })
                          }} variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography paragraph>What is their date of birth?</Typography>
                          <DatePicker key={`partner-dob`}
                            slotProps={{ textField: { fullWidth: true } }}
                            value={finances.personal?.dob?.partner ? dayjs(finances.personal?.dob?.partner, 'YYYY-MM-DD') : null}
                            onChange={(e) => {
                              financesDispatch({
                                type: 'update',
                                financeType: 'personal',
                                item: {
                                  ...finances.personal,
                                  dob: {
                                    ...finances.personal?.dob,
                                    partner: e ? e.format('YYYY-MM-DD') : null
                                  }
                                }
                              })
                            }}
                          />
                        </Grid>
                      </>
                    }
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Alert severity='info'>If you receive any other forms of income (such as benefits) as well as expenses, loans and savings later.</Alert>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5">Your housing</Typography>
              </Grid>
              <Grid item container xs={12}>
                <Paper style={{ width: '100%', padding: '20px' }}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={housingType == 'mortgage' ? 0 : 1} variant='fullWidth' aria-label="basic tabs example">
                          <Tab label="Mortgage" onClick={() => {
                            financesDispatch({
                              type: 'delete',
                              financeType: 'expenses',
                              id: finances.expenses.find((f) => f.type == 'rent').id
                            })

                            financesDispatch({
                              type: 'add',
                              financeType: 'loans',
                              item: {
                                name: 'Mortgage',
                                type: 'mortgage',
                                periods: [
                                  {
                                    duration: 300,
                                    addition: 0,
                                    apr: 0.05,
                                    start: dayjs().month(0).format('YYYY-MM-DD'),
                                    startAmount: 0,
                                    end: null
                                  }
                                ]
                              }
                            })
                            setHousingType('mortgage')
                          }
                          } />
                          <Tab label="Rent" onClick={() => {
                            financesDispatch({
                              type: 'delete',
                              financeType: 'loans',
                              id: finances.loans[0].id
                            })
                            if (!finances.expenses.find((f) => f.type == 'rent')) {
                              financesDispatch({
                                type: 'add',
                                financesType: 'expenses',
                                item: {
                                  type: 'rent',
                                  inflate: true,
                                  periods: [{
                                    start: `${dayjs().year()}-01-01`,
                                    end: null,
                                    pay: 'net',
                                    amount: 0
                                  }]
                                }
                              })
                            }
                            setHousingType('rent')
                          }
                          } />
                        </Tabs>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>

                      {housingType == 'rent' && (
                        <>
                          <MonthlyAnnualAmmount monthly label={ExpenseMap['rent']} value={finances.expenses.find((f) => f.id == 'rent') ? finances.expenses.find((f) => f.id == 'rent').periods[0].amount : 0} update={(e) => updateExp(e, 'rent')} />
                        </>
                      )}
                      {housingType == 'mortgage' && (
                        <>
                          <Grid container item spacing={2}>
                            <Grid container item spacing={2} xs={12}>
                              <Grid item xs={6}>
                                <InputLabel htmlFor="standard-adornment-amount">Start</InputLabel>
                                <DatePicker key={`mortgage-start`}
                                  views={['month', 'year']}
                                  format="MM/YYYY"
                                  disabled={true}
                                  slotProps={{ textField: { fullWidth: true }, actionBar: { actions: ['clear'] } }}
                                  value={dayjs().month(0)}
                                  onChange={(e) => {
                                    financesDispatch({
                                      type: 'update',
                                      financeType: 'loans',
                                      item: {
                                        ...finances.loans[0],
                                        periods: finances.loans[0].periods.map((m) => {
                                          m.start = e ? e.format('YYYY-MM-DD') : null
                                          m.end = e ? e.add(25, 'years').format('YYYY-MM-DD') : null
                                          return m
                                        })
                                      }
                                    })
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <InputLabel htmlFor="standard-adornment-amount">End</InputLabel>
                                <DatePicker key={`mortgage-start`}
                                  value={finances.loans[0].periods[0].end ? dayjs(finances.loans[0].periods[0].end) : null}
                                  views={['month', 'year']}
                                  format="MM/YYYY"
                                  minDate={dayjs().month(1)}
                                  slotProps={{ textField: { fullWidth: true }, actionBar: { actions: ['clear'] } }}
                                  onChange={(e) => {
                                    financesDispatch({
                                      type: 'update',
                                      financeType: 'loans',
                                      item: {
                                        ...finances.loans[0],
                                        periods: finances.loans[0].periods.map((m) => {
                                          m.end = e ? e.format('YYYY-MM-DD') : null
                                          return m
                                        })
                                      }
                                    })
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid container item spacing={2}>
                              <Grid item xs={6}>
                                <InputLabel htmlFor="standard-adornment-amount">Starting Amount</InputLabel>
                                <TextField helperText="Enter the amount as of the start date" InputProps={{
                                  inputComponent: MoneyFormat,
                                  startAdornment: <InputAdornment position="start">£</InputAdornment>,

                                }} variant='standard' fullWidth id="outlined-basic" key={`amount}`} onChange={(e) => {

                                  financesDispatch({
                                    type: 'update',
                                    financeType: 'loans',
                                    item: {
                                      ...finances.loans[0],
                                      periods: finances.loans[0].periods.map((m) => {
                                        m.startAmount = parseInt(e.target.value)
                                        return m
                                      })
                                    }
                                  })
                                }} defaultValue={finances.loans[0].periods[0].startAmount} />
                              </Grid>
                              <Grid item xs={6}>
                                <InputLabel htmlFor="standard-adornment-amount">Interest (APR)</InputLabel>
                                <TextField InputProps={{
                                  inputComponent: PercentageFormat,
                                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }} variant='standard' fullWidth id="outlined-basic" key={`amount}`} onChange={(e) => {
                                  financesDispatch({
                                    type: 'update',
                                    financeType: 'loans',
                                    item: {
                                      ...finances.loans[0],
                                      periods: finances.loans[0].periods.map((m) => {
                                        m.apr = parseFloat(e.target.value) / 100
                                        return m
                                      })
                                    }
                                  })
                                }} value={finances.loans[0].periods[0].apr * 100} />
                              </Grid>
                              <Grid item xs={12}>
                                <Alert severity='info'>You can add additional mortgages (and other loans), should you have them, later.</Alert>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Paper>

              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Your expenses</Typography>
              </Grid>
              <Grid item xs={12}>
                <Alert severity='info'>You can add loans, credit cards, etc. later.</Alert>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: '20px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <MonthlyAnnualAmmount monthly label={ExpenseMap['childcare']} value={finances.expenses.find((f) => f.id == 'childcare') ? finances.expenses.find((f) => f.id == 'childcare').periods[0].amount : 0} update={(e) => updateExp(e, 'childcare')} />
                    </Grid>
                    <Grid item xs={12}>
                      <MonthlyAnnualAmmount monthly label={ExpenseMap['energy']} value={finances.expenses.find((f) => f.id == 'energy') ? finances.expenses.find((f) => f.id == 'energy').periods[0].amount : 0} update={(e) => updateExp(e, 'energy')} />
                    </Grid>
                    <Grid item xs={12}>
                      <MonthlyAnnualAmmount monthly label={ExpenseMap['counciltax']} value={finances.expenses.find((f) => f.id == 'counciltax') ? finances.expenses.find((f) => f.id == 'counciltax').periods[0].amount : 0} update={(e) => updateExp(e, 'counciltax')} />
                    </Grid>
                    <Grid item xs={12}>
                      <MonthlyAnnualAmmount monthly label={ExpenseMap['water']} value={finances.expenses.find((f) => f.id == 'water') ? finances.expenses.find((f) => f.id == 'water').periods[0].amount : 0} update={(e) => updateExp(e, 'water')} />
                    </Grid>
                    <Grid item xs={12}>
                      <MonthlyAnnualAmmount monthly label={ExpenseMap['phone']} value={finances.expenses.find((f) => f.id == 'phone') ? finances.expenses.find((f) => f.id == 'phone').periods[0].amount : 0} update={(e) => updateExp(e, 'phone')} />
                    </Grid>
                    <Grid item xs={12}>
                      <MonthlyAnnualAmmount monthly label={ExpenseMap['broadband']} value={finances.expenses.find((f) => f.id == 'broadband') ? finances.expenses.find((f) => f.id == 'broadband').periods[0].amount : 0} update={(e) => updateExp(e, 'broadband')} />
                    </Grid>
                    <Grid item xs={12}>
                      <MonthlyAnnualAmmount monthly label={ExpenseMap['transportation']} value={finances.expenses.find((f) => f.id == 'transportation') ? finances.expenses.find((f) => f.id == 'transportation').periods[0].amount : 0} update={(e) => updateExp(e, 'transportation')} />
                    </Grid>
                    <Grid item xs={12}>
                      <MonthlyAnnualAmmount monthly label={ExpenseMap['fuel']} value={finances.expenses.find((f) => f.id == 'fuel') ? finances.expenses.find((f) => f.id == 'fuel').periods[0].amount : 0} update={(e) => updateExp(e, 'fuel')} />
                    </Grid>
                    <Grid item xs={12}>
                      <MonthlyAnnualAmmount monthly label={ExpenseMap['carinsurance']} value={finances.expenses.find((f) => f.id == 'carinsurance') ? finances.expenses.find((f) => f.id == 'carinsurance').periods[0].amount : 0} update={(e) => updateExp(e, 'carinsurance')} />
                    </Grid>
                    <Grid item xs={12}>
                      <MonthlyAnnualAmmount monthly label={ExpenseMap['holiday']} value={finances.expenses.find((f) => f.id == 'holiday') ? finances.expenses.find((f) => f.id == 'holiday').periods[0].amount : 0} update={(e) => updateExp(e, 'holiday')} />
                    </Grid>
                    <Grid item xs={12}>
                      <MonthlyAnnualAmmount monthly label={ExpenseMap['shopping']} value={finances.expenses.find((f) => f.id == 'shopping') ? finances.expenses.find((f) => f.id == 'shopping').periods[0].amount : 0} update={(e) => updateExp(e, 'shopping')} />
                    </Grid>
                    <Grid item xs={12}>
                      <MonthlyAnnualAmmount monthly label={ExpenseMap['homeinsurance']} value={finances.expenses.find((f) => f.id == 'homeinsurance') ? finances.expenses.find((f) => f.id == 'homeinsurance').periods[0].amount : 0} update={(e) => updateExp(e, 'homeinsurance')} />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

            </Grid>
          }


        </Grid>

        <Grid container item justifyContent="flex-end" xs={12}>
          {activeStep == 1 &&
            <Button onClick={() => {

              if (finances.loans.length > 0) {
                financesDispatch({
                  type: 'update',
                  financeType: 'loans',
                  id: 'mortgage',
                  item: {
                    ...finances.loans[0],
                    periods: [
                      {
                        ...finances.loans[0].periods[0],
                        duration: finances.loans[0].periods[0].end ? dayjs(finances.loans[0].periods[0].end, 'YYYY-MM-DD').diff(dayjs(finances.loans[0].periods[0].start, 'YYYY-MM-DD'), 'months') : finances.loans[0].periods[0].duration
                      }
                    ]
                  }
                })
              }

              if (!hasPartner) {
                financesDispatch({
                  type: 'delete',
                  financeType: 'income',
                  id: finances.income[1].id
                })
              }

              navigate(`/annual/${dayjs().year()}`)

            }}>FINISH</Button>
          }
        </Grid>
      </Grid >
    </>

  );
}

export default Collect;