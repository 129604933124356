import { Card, CardContent, Container, Paper, Step, StepLabel, Toolbar, Typography, Grid, TextField, Button, TableContainer, TableBody, TableRow, Table, TableHead, TableCell, CardMedia, CardActions, Select, MenuItem, InputLabel, Input, Slider, CardHeader, InputAdornment, Breadcrumbs, Accordion, AccordionSummary, AccordionDetails, styled, containerClasses, Modal, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton, Alert, radioClasses, FormControlLabel, Checkbox, duration, Tabs, Tab, Box } from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { Link, RouterProvider, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';

import InfoIcon from '@mui/icons-material/Info';

import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Info from '@mui/icons-material/Info';
import { calculateRepayments, createLoan, finances, getCredit, getLoan, getLoanMonthlyPayment, updateCredit, updateLoan } from '../store/store';
import { MoneyFormat, MoneyTextFormat, PercentTextFormat, PercentageFormat } from '../money';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useFinances, useFinancesDispatch } from '../store/FinancesContext';
import MonthlyAnnualAmmount from '../MonthlyAnnualAmount';
import { DataGrid } from '@mui/x-data-grid';
import DeleteDialog from '../deletedialog';
import Period from '../periods/Period';

function LoanPayment() {
    var { loanId } = useParams()
    var year = useSearchParams()[0].get('year')

    var navigate = useNavigate()

    const [showDelete, setShowDelete] = React.useState()

    var [selectedPeriod, setSelectedPeriod] = useState(null)

    var finances = useFinances()
    var financesDispatch = useFinancesDispatch()

    var rloan = finances.loans.find((f) => f.id == loanId)

    var [loan, setLoan] = useState(rloan ? rloan : {
        name: '',
        type: 'personal',
        periods: [
            {
                duration: 1,
                addition: 0,
                start: null,
                apr: 0,
                startAmount: 0,
                end: null
            }
        ]

    })

    loan.periods.sort((a, b) => {
        if (a.start == null || b.start == null) {
            return 1
        }

        return a.start - b.start
    })


    if (!selectedPeriod) {
        setSelectedPeriod(loan.periods[loan.periods.length - 1])
    }

    var repayments = calculateRepayments(finances, loan, parseInt(year))

    var calculatePeriodMonthly = (period) => {
        // TODO this is a bit funny because it's not considering months properly

        if (period?.interestonly) {
            var repay = repayments.find((y) => y.year == dayjs(period.start, 'YYYY-MM-DD').year())
            return repay ? (repay.endAmount * period.apr) / 12 : 0
        }

        var start = dayjs(period?.start, 'YYYY-MM-DD')
        var end = period?.end != null ? dayjs(period?.end, 'YYYY-MM-DD').add(1, 'months') : dayjs(period?.start, 'YYYY-MM-DD').add(period?.duration, 'months')

        var monthsInPeriod = end.diff(start, 'month')

        var totalPaymentsInPeriod = repayments.reduce((prev, cur) => {
            if (cur.year >= start.year() && (period.end == null || cur.year <= end.year())) {
                if (cur.year == start.year()) {
                    if (start.month() > 0 && cur.months < 12) {
                        return prev + cur.payments
                    } else {
                        return prev + (((12 - start.month()) / cur.months) * cur.payments)
                    }
                } else if (cur.year > start.year() && cur.year < end.year()) {
                    return prev + cur.payments
                } else if (cur.year == end.year()) {
                    return prev + (((end.month()) / cur.months) * cur.payments)
                }
            }

            return prev
        }, 0)

        return (totalPaymentsInPeriod / monthsInPeriod)
    }

    if (Array.isArray(repayments)) {
        var yearCalc = repayments.find((f) => f.year == dayjs(year, 'YYYY-MM-DD').year())

        if (repayments.length > 0) {
            yearCalc = yearCalc ? yearCalc : repayments[repayments.length - 1]
        } else {
            yearCalc = {
                year: parseInt(year),
                startAmount: 0,
                endAmount: 0,
                interest: 0,
                payments: 0
            }
        }
    }


    return (
        <>

            {showDelete &&
                <DeleteDialog delete={showDelete.delete} name={showDelete.name} handleClose={() => setShowDelete(null)} />
            }
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12}>
                    <Paper style={{ width: '100%', padding: '20px' }}>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="standard-adornment-amount">Loan Type</InputLabel>
                                <Select
                                    value={loan.type}
                                    onChange={(e) => {
                                        setLoan({
                                            ...loan,
                                            type: e.target.value
                                        })
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value={'personal'}>Personal</MenuItem>
                                    <MenuItem value={'mortgage'}>Mortgage</MenuItem>
                                    <MenuItem value={'student'}>Student</MenuItem>
                                </Select>
                            </Grid>
                            {loan.type === 'student' && <Grid item xs={12}>
                                <InputLabel htmlFor="standard-adornment-amount">Student Loan Plan</InputLabel>
                                <Select
                                    value={loan.subtype}
                                    onChange={(e) => {
                                        setLoan({
                                            ...loan,
                                            subtype: e.target.value
                                        })
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value={'plan1'}>Plan 1</MenuItem>
                                    <MenuItem value={'plan2'}>Plan 2</MenuItem>
                                    <MenuItem value={'plan4'}>Plan 4</MenuItem>
                                    <MenuItem value={'plan5'}>Plan 5</MenuItem>
                                    <MenuItem value={'postgraduate'}>Postgraduate</MenuItem>

                                </Select>
                            </Grid>}
                            {loan.type == 'student' && <Grid item xs={12}>
                                <InputLabel htmlFor="standard-adornment-amount">Who</InputLabel>
                                <Select
                                    value={loan.who}
                                    onChange={(e) => {
                                        setLoan({
                                            ...loan,
                                            who: e.target.value
                                        })
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value={'you'}>You</MenuItem>
                                    <MenuItem value={'partner'}>Partner</MenuItem>
                                </Select>
                            </Grid>}
                            {loan.type != 'student' && <Grid item xs={12}>
                                <InputLabel htmlFor="standard-adornment-amount">Name</InputLabel>
                                <TextField variant='standard' fullWidth id="outlined-basic" defaultValue={loan.name} onChange={(e) => { setLoan({ ...loan, name: e.target.value }) }} />
                            </Grid>}

                        </Grid>

                    </Paper>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                    <Grid item xs={6}>
                        <Card style={{ height: '100%' }}>
                            <CardContent>
                                <Typography variant='h6'>Repayments</Typography>
                                <Typography variant='h4'><MoneyTextFormat value={Array.isArray(repayments) ? Math.round(repayments.reduce((prev, cur) => prev + cur.payments, 0)) : 0} /></Typography>
                                <Typography color='grey'>Over {repayments.length ? repayments.length : 0} years</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card style={{ height: '100%' }}>
                            <CardContent>
                                <Typography variant='h6'>Interest</Typography>
                                <Typography variant='h4'><MoneyTextFormat value={Array.isArray(repayments) ? Math.round(repayments.reduce((prev, cur) => prev + cur.interest, 0)) : 0} /></Typography>
                                <Typography color='grey'><PercentTextFormat value={(Math.round(repayments.reduce((prev, cur) => prev + cur.interest, 0)) / Math.round(repayments.reduce((prev, cur) => prev + cur.payments, 0))) * 100} /> of repayments</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container item spacing={2} xs={12}>
                    <Grid item xs={12}>
                        {repayments.length > 0 && repayments[repayments.length - 1].endAmount > 0 && <Alert severity='warning'>It looks like you won't pay back your loan</Alert>}
                    </Grid>
                </Grid>
                <Period
                    monthly={true} periods={loan.periods} selectedPeriod={selectedPeriod} update={() => {
                        setLoan({
                            ...loan
                        })
                    }} editLink={(selYear) => {
                        var p = selYear && loan.periods.find((f) => selYear.format('YYYY-MM-DD') == f.start)
                        setSelectedPeriod(p ? p : loan.periods[loan.periods.length - 1])
                    }} getAmount={(period) => {
                        return period.monthly ? period.monthly : calculatePeriodMonthly(period)
                    }} addPeriod={(periods) => {
                        periods.push({
                            start: null,
                            end: null,
                            addition: 0,
                            apr: 0
                        })
                    }}>
                    <Grid container spacing={2} item xs={12}>
                        {loan.type != 'student' && <Grid item xs={6}>
                            <InputLabel htmlFor="standard-adornment-amount">Duration</InputLabel>
                            <TextField InputProps={{
                                endAdornment: <InputAdornment position="end">months</InputAdornment>
                            }} fullWidth id="outlined-basic" key={`duration-${selectedPeriod?.start}`} defaultValue={selectedPeriod?.duration} onChange={(e) => {
                                setLoan(
                                    {
                                        ...loan,
                                        periods: loan.periods.map((m) => {
                                            if (m.start == selectedPeriod.start) {
                                                return {
                                                    ...m,
                                                    monthly: null,
                                                    duration: parseInt(e.target.value)
                                                }
                                            }

                                            return m
                                        })
                                    })
                            }}
                                variant='outlined' />
                        </Grid>}
                        <Grid item xs={loan.type =='student' ? 12 : 6}>
                            <InputLabel htmlFor="standard-adornment-amount">Starting Amount</InputLabel>
                            <TextField InputProps={{
                                inputComponent: MoneyFormat,
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,

                            }} variant='outlined' fullWidth placeholder={Math.round(selectedPeriod?.start ? repayments.find((f) => f.year == dayjs(selectedPeriod?.start, 'YYYY-MM-DD').year())?.startAmount : 0)} id="outlined-basic" key={`start-amount-${selectedPeriod?.start}`} defaultValue={selectedPeriod?.startAmount} onChange={(e) => {
                                setLoan({
                                    ...loan,
                                    periods: loan.periods.map((m) => {
                                        var val = parseFloat(e.target.value)
                                        if (m.start == selectedPeriod?.start && isNaN(val)) {
                                            m.startAmount = null
                                            m.monthly = null
                                        } else if (m.start == selectedPeriod?.start) {
                                            m.startAmount = parseFloat(e.target.value)
                                            m.monthly = null
                                        }

                                        return m
                                    })
                                })
                            }} />
                        </Grid>
                        {loan.type != 'student' && <> <Grid item xs={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={selectedPeriod?.interestonly ? 1 : 0} variant='fullWidth' aria-label="basic tabs example">
                                    <Tab label="Repayment" onClick={(e) => {
                                        setLoan({
                                            ...loan,
                                            periods: loan.periods.map((m) => {
                                                if (m.start === selectedPeriod?.start) {
                                                    m.interestonly = false
                                                }

                                                return m
                                            })
                                        })
                                    }} />
                                    <Tab label="Interest Only" onClick={(e) => {
                                        setLoan({
                                            ...loan,
                                            periods: loan.periods.map((m) => {
                                                if (m.start === selectedPeriod?.start) {
                                                    m.interestonly = true
                                                }

                                                return m
                                            })
                                        })
                                    }} />
                                </Tabs>
                            </Box>
                        </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="standard-adornment-amount">Interest (APR)</InputLabel>
                                <TextField InputProps={{
                                    inputComponent: PercentageFormat,
                                }} disabled={loan.type == 'student'} fullWidth id="outlined-basic" key={`apr-${selectedPeriod?.start}`} value={selectedPeriod?.apr * 100} onChange={(e) => {
                                    setLoan({
                                        ...loan,
                                        periods: loan.periods.map((m) => {
                                            if (m.start == selectedPeriod?.start) {
                                                m.apr = parseFloat(e.target.value) / 100
                                            }

                                            return m
                                        })
                                    })
                                }
                                } variant="outlined" />
                            </Grid>
                            <Grid item xs={12} md={6}>

                                {loan.type != 'student' && <>
                                    <InputLabel htmlFor="standard-adornment-amount">Repayments</InputLabel>
                                    <MonthlyAnnualAmmount key={`amount-${selectedPeriod?.start}`} disabled={selectedPeriod?.interestonly} update={(value) => {
                                        setLoan({
                                            ...loan,
                                            periods: loan.periods.map((m) => {
                                                if (m.start == selectedPeriod?.start) {
                                                    if (m.interestonly) {
                                                        m.monthly = null
                                                    } else if (value) {
                                                        m.monthly = parseFloat(value) / 12
                                                    } else {
                                                        m.monthly = null
                                                    }
                                                }

                                                return m
                                            })
                                        })
                                    }} monthly value={selectedPeriod?.monthly ? selectedPeriod?.monthly * 12 : calculatePeriodMonthly(selectedPeriod) * 12} />
                                </>
                                }
                            </Grid></>}



                        <Grid item xs={12}>
                            {loan.type == 'student' &&
                                <Alert severity='info'>9% of net income</Alert>
                            }

                        </Grid>
                        <Grid item container xs={12}>
                            <InputLabel htmlFor="standard-adornment-amount">Comments</InputLabel>
                            <TextField
                                id="filled-multiline-flexible"
                                key={`amount-${selectedPeriod?.start}`}
                                defaultValue={selectedPeriod?.comments}
                                onChange={(e) => {
                                    setLoan({
                                        ...loan,
                                        periods: loan.periods.map((m) => {
                                            if (m.start == selectedPeriod?.start) {
                                                m.comments = e.target.value
                                            }

                                            return m
                                        })
                                    })
                                }}
                                fullWidth
                                multiline
                                maxRows={4}
                            />
                        </Grid>

                    </Grid>

                </Period>

                <Grid container item spacing={2} justifyContent='flex-end'>
                    <Grid item>
                        <Button variant='outlined' onClick={(a) => {
                            setShowDelete({
                                delete: () => {
                                    financesDispatch({
                                        type: 'delete',
                                        financeType: 'loans',
                                        id: loan.id
                                    })
                                    setShowDelete(null)
                                    navigate(-1)
                                },
                                name: loan.name
                            })
                        }}>Delete</Button>
                    </Grid>
                    <Grid item>
                        {loan.id ? <Button onClick={() => { financesDispatch({ type: 'update', financeType: 'loans', item: loan }); navigate(-1) }} variant='contained'>Update Loan</Button> : <Button onClick={() => { financesDispatch({ type: 'add', financeType: 'loans', item: { ...loan, name: loan.type == 'student' ? `Student Loan (${loan.who == 'you' ? 'You' : 'Partner'})` : loan.name, id: loan.type == 'student' ? `${loan.who}${loan.type}${loan.subtype}` : null } }); navigate(-1) }} variant='contained'>Add Loan</Button>}
                    </Grid>

                </Grid>

            </Grid >
        </>


    );
}

export default LoanPayment;
