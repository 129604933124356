import { createContext, useContext, useReducer } from 'react';
import { getAnnualIncome, saveJson } from './store';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

const FinancesContext = createContext(null);

const FinancesDispatchContext = createContext(null);

export function FinancesProvider({ children }) {
    const [tasks, dispatch] = useReducer(
        financesReducer,
        initialFinances
    );

    return (
        <FinancesContext.Provider value={tasks}>
            <FinancesDispatchContext.Provider value={dispatch}>
                {children}
            </FinancesDispatchContext.Provider>
        </FinancesContext.Provider>
    );
}

export function useFinances() {
    return useContext(FinancesContext);
}

export function useFinancesDispatch() {
    return useContext(FinancesDispatchContext);
}

export function calculateAnnualIncome(finances) {
    var minYear = finances.income?.reduce((prev, cur) => {
        var per = dayjs(cur.periods.reduce((pPrev, pCur) => {
            return pCur.start ? dayjs(pCur.start, 'YYYY-MM-DD') < dayjs(pPrev) ? pCur.start : pPrev : pPrev
        }, dayjs()), 'YYYY-MM-DD')

        return dayjs(per, 'YYYYY-MM-DD') < prev ? per : prev
    }, dayjs())

    finances.annualIncome = {}

    for (var i = minYear.year(); i < 2100; i++) {
        finances.annualIncome[i] = getAnnualIncome(finances, i)
    }
}

function saveFinances(finances) {
    // TODO perhaps convert all dates to dayjs when loading and then when saving, turn them back into strings
    saveJson({
        income: finances.income,
        expenses: finances.expenses,
        personal: finances.personal,
        savings: finances.savings,
        loans: finances.loans,
        credit: finances.credit
    }).catch((e) => { })
}

function financesReducer(finances, action) {
    if (action.item || action.id) {

        switch (action.type) {
            case 'add': {
                !finances[action.financeType].find((f) => f.id == action.item.id) && finances[action.financeType].push(action.item)

                if (!action.item.id) action.item.id = uuidv4()

                if (action.financeType == 'expenses') {
                    action.item.periods.forEach((f) => {
                        var cc = finances.credit.find((c) => c.id == f.pay && f.start >= c.start && (f.end <= c.end || c.end == null))
                        if (cc) {
                            cc.periods.push(
                                {
                                    addition: 0,
                                    apr: 0,
                                    start: f.start,
                                    startAmount: 0,
                                    monthly: 0,
                                    end: null
                                }
                            )
                        }
                    })

                }
                calculateAnnualIncome(finances)

                saveFinances(finances)
                return { ...finances }
            }
            case 'update': {
                if (action.financeType == 'personal') {
                    finances.personal = action.item
                    saveJson(finances).catch((e) => { })
                    return { ...finances }
                }

                finances[action.financeType] = finances[action.financeType].map(t => {
                    if (t.id === action.item.id) {
                        return action.item;
                    } else {
                        return t;
                    }
                });
                calculateAnnualIncome(finances)

                saveFinances(finances)
                return { ...finances }
            }
            case 'delete': {
                finances[action.financeType] = finances[action.financeType].filter(t => t.id !== action.id);
                calculateAnnualIncome(finances)

                saveFinances(finances)
                return { ...finances }
            }
            case 'reload': {
                calculateAnnualIncome(action.item)
                return action.item
            }
            case 'reset': {
                calculateAnnualIncome(initialFinances)
                return initialFinances
            }
            default: {
                throw Error('Unknown action: ' + action.type);
            }
        }

    }

    return finances
}

const initialFinances = {
    income: [],
    savings: [],
    expenses: [],
    credit: [],
    loans: []
}
